@import "../style/variables.scss";

.pVision {
  padding: 35px;
  background-color: $blue;
  padding-bottom: 80px;
  h2 {
    color: white;
    font-size: 65px;
    text-align: center;
    margin-bottom: 70px;
    margin-top: 30px;
    @media (max-width: $mob) {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
  &--skills {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    @media (max-width: $tab) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $mob) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    > div {
      position: relative;
      width: 460px;
      height: 460px;
      padding-bottom: 40px;
      background-color: $beige;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      margin-top: 30px;
  
      @media (max-width: 1530px) {
        width: 400px;
        height: 400px;
      }
  
      @media (max-width: 1330px) {
        width: 370px;
        height: 370px;
      }
      @media (max-width: 1200px) {
        width: 310px;
        height: 310px;
      }
      @media (max-width: $tab) {
        width: 350px;
        height: 350px;
      }
  
      h3 {
        text-align: center;
        margin-top: 25px;
        margin-bottom: 20px;
        font-size: 25px;
  
        @media (max-width: 1300px) {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
  
      p {
        width: 90%;
        font-size: 16px;
        line-height: 20px;
  
        @media (max-width: 1530px) {
          font-size: 14px;
          line-height: 16px;
        }
        @media (max-width: 1330px) {
          font-size: 12px;
          line-height: 12px;
        }
        @media (max-width: $tab) {
          font-size: 14px;
          line-height: 13px;
        }
      }
  
      > div {
        position: absolute;
        width: 35px;
        height: 35px;
        background-color: $lightPink;
        color: white;
        top: -10px;
        left: -5px;
        border-radius: 50px;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 25px;
  
        span {
          color: white;
        }
      }
    }
  }
}