
$beige: #F1EEDE;
$lightPink: #EFC4D3;
$pink: #D37C9D;
$lightBlue: #ACD2FF;
$blue: #335398;
$yellow: #FADD9B;
$text: #1e181c;

$handWriting: 'Kristi', cursive;
$fontText: 'Josefin Sans', sans-serif;
$fontTitle: 'Yeseva One', serif;

$timing: 265ms;
$tab: 1023px;
$mob: 768px;