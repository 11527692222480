@import "../style/variables.scss";

.pContact {
  display: flex;
  padding: 3%;
  background-color: $blue;
  @media (max-width: $tab) {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  @media (max-width: $mob) {
    flex-direction: column-reverse;
    align-items: center;
  }
  &--infos {
    width: 40%;
    display: flex;
    flex-direction: column;
    margin-top: 80px;
    padding-left: 5%;
    color: white;
    @media (max-width: $tab) {
      padding: initial;
    }
    @media (max-width: $mob) {
      width: 100%;
    }
    h2 {
      font-size: 50px;
      margin-bottom: 30px;
      color: white;
      @media (max-width: $tab) {
        font-size: 40px;
      }
      @media (max-width: $mob) {
        text-align: center;
        margin-bottom: 40px;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      @media (max-width: $mob) {
        margin-left: 10%;
      }
      .pContact--infos--item {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        &:hover{
          .pContact--infos--item--text--link::after {
            transform: scaleX(1);
          }
        }
        &--text {
          color: white;
          font-size: 20px;
          @media (max-width: $tab) {
            font-size: 15px;
          }
          &--link {
            position: relative;
            transition: ease-out 0.3s 0.1s;
            &::after {
              height: 2px;
              width: 100%;
              background: white;
              content: "";
              position: absolute;
              left: 0px;
              bottom: -4px;
              transform: scaleX(0);
              transition: 0.3s;
            }
          }
        }
        &--icon {
          color: white;
          margin-right: 10px;
          font-size: 25px;
          background: $pink;
          padding: 5px 8px;
          border-radius: 5px;
          @media (max-width: $tab) {
            font-size: 20px;
          }
        }
      }
    }
  }
  &--form {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $tab) {
      width: 80%;
    }
    @media (max-width: $mob) {
      width: auto;
    }
    h2 {
      text-align: center;
      font-size: 45px;
      margin-bottom: 40px;
      color: white;
      font-size: 60px;
      font-weight: 400;
      @media (max-width: $mob) {
        font-size: 50px;
      }
    }
    &--textarea {
      flex-direction: column;
    }
    form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 80%;
      max-width: 550px;
      @media (max-width: $mob) {
        width: 90%;
      }
      div div:first-child {
        margin-bottom: 25px;
      }
      > div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        width: 100%;
        @media (max-width: $tab) {
          flex-direction: column;
          align-items: center;
        }
        input {
          height: 35px;
          width: 210px;
          padding-left: 10px;
          border-radius: 5px;
          border: none;
        }
      }
      textarea {
        height: 300px;
        width: 100%;
        resize: none;
        padding: 20px;
        border-radius: 5px;
        border: none;
        @media (max-width: $tab) {
          height: 200px;
        }
      }
      button {
        height: 50px;
        width: 180px;
        background-color: $pink;
        color: white;
        padding: 15px 25px;
        border-radius: 10px;
        font-family: "Yeseva One", serif;
        transition: background-color 0.3s ease;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 200;
        letter-spacing: 1px;
        &:hover {
          background-color: $lightPink;
        }
      }
    }
  }
}

.error-message {
  color: #FF7276;
  margin-top: 15px;
  @media (max-width: $mob) {
    max-width: 200px;
  }
}
.success-message {
  color: #C7EA46;
  margin-top: 25px;
  font-size: 17px;
}
.error-message-input {
  max-width: 210px;
}
.error-general-message {
  margin-top: 25px;
  font-size: 17px;
  max-width: inherit;
}