@import "../style/variables.scss";

.pAbout {
  padding-top: 35px;
  background-color: $beige;
  h2 {
    text-align: center;
    font-size: 80px;
    margin-top: 20px;
    @media (max-width: $mob) {
      font-size: 60px;
    }
  }
  &--main {
    display: flex;
    padding: 30px;
    @media (max-width: $mob) {
      flex-direction: column-reverse;
    }
    &--music {
      width: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: $tab) {
        margin-top: 100px;
      }
      @media (max-width: $mob) {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 25px;
      }
      img {
        height: 500px;
        margin-bottom: 25px;
        @media (max-width: $tab) {
          height: 350px;
          width: 350px;
        }
      }
      > p {
        margin-bottom: 35px;
        font-size: 17px;
        font-weight: 600;
        text-align: center;
      }
      .player {
        > div:first-child {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          h3 {
            font-size: 25px;
            margin-bottom: 15px;
            color: $blue;
          }
          p {
            font-size: 17px;
            margin-bottom: 15px;
          }
        }
        &--time {
          display: flex;
          flex-direction: column;
          div {
            display: flex;
            justify-content: space-between;
          }
          p {
            font-size: 17px;
          }
        }
        &--btn {
          display: flex;
          justify-content: center;
          button {
            font-size: 27px;
            margin-top: 15px;
            color: $blue;
          }
        }
      }
    }
    &--infos {
      width: 60%;
      padding: 20px;
      @media (max-width: $mob) {
        width: 100%;
        padding: 0px;
      }
       > div:first-child {
        padding: 5%;
        padding-top: 0;
        p {
          font-size: 21px;
          line-height: 28px;
          margin-bottom: 25px;
          @media (max-width: $tab) {
            text-align: center;
            font-size: 18px;
          }
        }
      } 
      &--weather {
        margin-top: 40px;
        text-align: center;
        font-size: 17px;
        font-weight: 600;
      }
      &--hobbies {
        margin-top: 25px;
        h3 {
          margin-bottom: 45px;
          text-align: center;
          font-size: 27px;
        }
        > div {
          div:first-child {
            display: flex;
            .pAbout--main--infos--hobbies--container {
              height: 200px;
              width: 25%;
              background: $blue;
              margin-right: 10px;
              display: flex;
              flex-direction: column;
              align-items: center;
              padding-top: 20px;
              border-radius: 15px;
              color: white;
              @media (max-width: $tab) {
                width: 50%;
              }
              > div {
                justify-content: center;
                align-items: center;
                height: 100px;
                img {
                  height: 100%;
                  object-fit: contain;
                }
              }
              p {
                margin-top: 15px;
                font-size: 17px;
                text-align: center;
                margin-left: 5%;
                margin-right: 5%;
                line-height: 20px;
                text-transform: capitalize;
              }
            }
            > .pAbout--main--infos--hobbies--container:nth-last-child(-n+2) {
              @media (max-width: $tab) {
                display: none;
              }
            }
          }
        }
        &--btn {
          display: flex;
          justify-content: center;
          margin-top: 35px;
          margin-bottom: 35px;
          button {
            background-color: $pink;
            color: white;
            padding: 15px 25px;
            border-radius: 10px;
            font-size: 17px;
            font-family: $fontTitle;
            transition: background-color 0.3s ease;
            &:hover {
              background-color: $lightPink;
            }
          }
        }
      }
    }
  }
}

input[type="range"] {
  -webkit-appearance: none;
   appearance: none;
   background: transparent;
   cursor: pointer;
   width: 100%;
   margin-top: 15px;
}

input[type="range"]:focus {
 outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  background-color: $pink;
  border-radius: 5px;
  height: 10px;  
}

/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
 -webkit-appearance: none; 
  appearance: none;
  margin-top: -2px; 
  background-color: $blue;
  height: 15px;
  width: 15px;
  border-radius: 20px;
}

/******** Firefox styles ********/
/* slider track */
input[type="range"]::-moz-range-track {
  background-color: $pink;
  border-radius: 5px;
  height: 10px;  
}

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  border: none; 
  background-color: $blue;
  height: 15px;
  width: 15px;
  border-radius: 20px;
}
