@import "../style/variables.scss";

.footer {
  background-color: $pink;
  padding: 5% 10%;
  @media (max-width: $tab) {
    padding: 5%;
  }
  &__info {
    display: flex;
    justify-content: space-between;
    color: white;
    @media (max-width: $mob) {
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .pContact--infos {
      width: auto;
      display: block;
      margin: inherit;
      padding: inherit;
      @media (max-width: $mob) {
        padding: initial;
      }
    }
    .pContact--infos--item--icon {
      background-color: $blue !important;
    }
    &__contact {
      h1 {
        font-size: 35px;
        margin-bottom: 40px;
        @media (max-width: $tab) {
          font-size: 30px;
        }
      }
      > div {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: baseline;
        > a {
          margin-bottom: 10px;
        }
      }
    }
    &__socials {
      display: flex;
      width: 30%;
      @media (max-width: $tab) {
        width: 35%;
      }
      @media (max-width: $mob) {
        width: 80%;
        margin-bottom: 20px;
      }
      > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        a:before {
          background: $blue !important;
        }
      }
    }
    &__logo {
      display: flex;
      align-items: center;
      img {
        background: $blue;
        border-radius: 70px;
        height: 140px;
        padding-top: 15px;
        padding-bottom: 15px;
        @media (max-width: $tab) {
          height: 120px;
          width: 120px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        @media (max-width: $mob) {
          height: 100px;
          width: 100px;
        }
      }
    }
  }
  &__menu {
    @media (max-width: $mob) {
      display: none;
    }
    ul {
      display: flex;
      margin-top: 50px;
      margin-right: 20%;
      justify-content: space-between;
      @media (max-width: $tab) {
        margin: auto;
      }
      a {
        color: white;
        cursor: pointer;
        white-space: nowrap;
        font-size: 17px;
        font-weight: 500;
        text-transform: uppercase;
        transition: filter 0.3s ease;
        &:hover {
          filter: brightness(85%);
        }
      }
    }
  }
}