@import "../style/variables.scss";

.pExperiences {
  background-color: $blue;
  padding: 30px;
  position: relative;
  padding-bottom: 80px;
  &--cv {
    position: absolute;
    right: 3%;
    top: 4%;
    height: 200px;
    width: 200px;
    cursor: pointer;
    animation: rotation 15s infinite linear;
    @media (max-width: $tab) {
      height: 150px;
      width: 150px;
      top: 1%;
    }
    @media (max-width: $mob) {
      height: 100px;
      width: 100px;
      top: 2%;
      right: 10%;
    }
  }
  h2 {
    text-align: center;
    margin-top: 35px;
    margin-bottom: 80px;
    font-size: 70px;
    font-weight: 100;
    color: white;
    @media (max-width: $tab) {
      margin-bottom: 100px;
      margin-top: 50px;
    }
    @media (max-width: $mob) {
      margin-bottom: 50px;
      margin-top: 100px;
      font-size: 60px;
    }
  }
  &--main {
    display: flex;
    margin-left: 10%;
    @media (max-width: $tab) {
      margin-left: 5%;
    }
    @media (max-width: $mob) {
      display: none;
    }
    &--mob {
      @media (min-width: $mob) {
        display: none;
      }
    }
    &--graph {
      position: relative;
      width: 20%;
      @media (max-width: $mob) {
        width: auto;
      }
      &:before {
        transition: all 0.2s ease 0s;
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 0.5rem;
        height: 100%;
        background: white;
        z-index: 100;
        border-radius: 2px;
      }
      div {
        height: 100px;
        position: relative;
        display: flex;
        padding-left: 25px;
        align-items: center;
        cursor: pointer;
        font-family: $fontText;
        color: white;
        font-size: 20px;
        font-weight: 800;
        @media (max-width: $mob) {
          height: auto;
          padding-left: 10px;
          margin-top: 25px;
          margin-bottom: 25px;
          font-size: 16px;
        }
        &:after {
          transition: all 0.2s ease 0s;
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 0%;
          height: 0.5rem;
          background: white;
          z-index: 100;
          border-radius: 2px;
        }
        > span {
          color: white;
          font-size: 25px;
          transition: transform 0.3s ease;
        }
        >p {
          padding-right: 5%;
        }
      }
      .active{
        @media (max-width: $mob) {
          flex-direction: column;
        }
        p {
          color: $pink;
        }
        span {
          transform: rotate(-180deg);
          transition: transform 0.3s ease;
        }
        &:after {
          width: 100%;
        }
      }
    }
    &--descr {
      margin-left: 50px;
      width: 50%;
      color: white;
      @media (max-width: $tab) {
        margin-left: 70px;
        width: auto;
        margin-right: 5%;
      }
      &--date {
        margin-bottom: 25px;
        font-size: 22px;
        font-weight: 800;
      }
      &--text {
        margin-bottom: 20px;
        font-size: 20px;
        line-height: 20px;
        font-weight: 300;
        @media (max-width: $mob) {
          margin-left: 5%;
          padding-left: 0;
          padding-right: 0 !important;
          color: white !important;
        }
      }
      &--skills {
        display: flex;
        flex-wrap: wrap;
        margin-top: 40px;
        @media (max-width: $mob) {
          margin-top: 0 !important;
        }
        > p {
          font-size: 18px;
          margin: 10px;
          padding: 8px;
          background-color: $pink;
          border-radius: 5px;
          color: white !important;
          @media (max-width: $mob) {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}