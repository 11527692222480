*,
*::before,
*::after {
  box-sizing: border-box;
  margin:0;
  padding:0;
}

body, a, span, input, textarea {
  font-family: $fontText;
  margin: 0;
  color: $text;
}

h1,h2,h3 {
  font-family: $fontTitle;
}

ul, ol, li {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: $text;
}

input:focus, textarea:focus {
  outline: none;
}