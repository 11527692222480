@import "../style/variables.scss";
@import "../style/mixins.scss";

.pMain {
  &--section {
    display: flex;
    background-color: $beige;
    padding: 50px;
    padding-bottom: 80px;
    @media (max-width: $mob) {
      padding: 5%;
      flex-direction: column;
    }
    &--text {
      width: 50%;
      @media (max-width: $mob) {
        width: 100%;
      }
      h1 {
        font-size: 125px;
        font-weight: 100;
        line-height: 150px;
        padding-left: 5%;
        color: $pink;
        margin-bottom: 25px;
        @media (max-width: $tab) {
          font-size: 115px;
        }
        @media (max-width: $mob) {
          margin-bottom: 450px;
          padding-left: 0;
          font-size: 60px;
          text-align: center;
        }
      }
      p {
        padding: 0 2% 2% 2%;
        font-size: 25px;
        color: $blue;
        line-height: 40px;
        @media (max-width: $tab) {
          font-size: 20px;
        }
        @media (max-width: $mob) {
          text-align: center;
        }
      }
      span {
        font-size: 25px;
        color: $blue;
        padding-left: 2%;
      }
      &--btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        button {
          border: none;
          border: 1px solid $blue;
          padding: 20px 22px;
          border-radius: 90%;
          font-size: 20px;
          cursor: pointer;
          position: relative;
          background-color: transparent;
          &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            transition: all 0.25s ease;
            border-radius: 90%;
          }
          &::before{
            transition-duration: 0.5s;
            box-shadow: inset 0 0 0 1px $blue;
          }
          &:hover::before{
            box-shadow: inset 0 0 0 60px $blue;
          }
          &:hover {
            color: white;
          }
          svg {
            position: relative;
          }
        }
        span {
          margin-top: 15px;
        }
      }
    }
    &--pic {
      display: flex;
      justify-content: center;
      position: relative;
      margin-top: 100px;
      padding-left: 10%;
      @media (max-width: $tab) {
        margin-top: 270px !important;
      }
      @media (max-width: $mob) {
        position: absolute;
        top: 370px;
        margin-top: auto !important;
        width: auto !important;
        padding-left: 5%;
        left: 20%;
      }
      @media (max-width: 1096px) {
        width: 50%;
      }
      @media (max-width: 490px) {
        left: 15%;
      }
      @media (max-width: 420px) {
        left: 5%;
      }
      @media (max-width: 1261px) {
        margin-top: 170px;
      }
      &--arrow {
        position: absolute;
        width: 200px;
        display: flex;
        top: -110px;
        right: 20px;
        @media (max-width: $tab) {
          right: 0px;
        }
        @media (max-width: $mob) {
          right: -50px;
        }
        span {
          font-family: $handWriting;
          color: $pink;
          position: absolute;
          font-size: 40px;
          transform: rotate(4deg);
          right: -15px;
          top: 0px;
          @media (max-width: $tab) {
            right: -5px;
          }
          @media (max-width: $mob) {
            right: 15px;
          }
          @media (max-width: 420px) {
            font-size: 35px;
            right: 20px;
          }
        }
        img {
          height: 120px;
          width: 120px;
          transform: scaleX(-1) rotate(15deg);
          @media (max-width: $mob) {
            width: 100px;
          }
          @media (max-width: 420px) {
            left: 80px;
          }
        }
      }
      &--border {
        width: 445px;
        position: absolute;
        height: 445px;
        border: dashed 5px;
        border-radius: 90%;
        border-color: $lightBlue;
        @media (max-width: $tab) {
          width: 345px;
          height: 345px;
        }
        @media (max-width: $mob) {
          width: 295px;
          height: 290px;
        }
      }
      &--container {
        background: url("../../assets/images/pink-bg.png");
        background-size: cover;
        border-radius: 90%;
        width: 400px;
        min-width: 400px;
        height: 400px;
        display: flex;
        justify-content: center;
        overflow: hidden;
        border: 5px solid;
        z-index: 1;
        position: relative;
        margin: 20px;
        border-color: $lightBlue;
        @media (max-width: $tab) {
          width: 300px;
          min-width: 300px;
          height: 300px;
        }
        @media (max-width: $mob) {
          width: 250px;
          min-width: 250px;
          height: 250px;
        }
        img {
          height: 640px;
          width: 400px;
          object-fit: fill;
          @media (max-width: $tab) {
            height: 440px;
            width: 280px;
          }
          @media (max-width: $mob) {
            height: 340px;
            width: 200px;
          }
        }
      }
    }
  }
  &--banner {
    background-color: $blue;
    padding: 5px 5%;
    @media (max-width: $mob) {
      padding-left: 0;
      padding-right: 0;
    }
    ul {
      display: flex;
      justify-content: space-between;
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      a {
        color: white;
        display: flex;
        align-items: center;
        span {
          margin-left: 15px;
        }
      }
    }
  }
}

#btn__container {
  margin-left: 20%;
  margin-top: 60px;
  @media (max-width: $tab) {
    margin-left: 60%;
  }
  @media (max-width: $mob) {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-left: 0;
  }
}

button {
  position: relative;
  cursor: pointer;
  border: 0;
  background: transparent;
  font-size: inherit;
  width: 19rem;
  &.contactMeBtn{
    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: relative;
      display: block;
      width: 3rem;
      height: 3rem;
      background: $pink;
      border-radius: 1.625rem;
      .icon {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid white;
            border-right: 0.125rem solid white;
            transform: rotate(45deg);
          }
        }
      }
    }
    .btn__text {
      @include transition(all, 0.45s, cubic-bezier(0.65,0,.076,1));
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: $pink;
      font-weight: 700;
      text-transform: uppercase;
    }
  }
  &:hover {
    .circle {
      width: 100%;
      .icon {
        &.arrow {
          background: white;
          transform: translate(1rem, 0);
        }
      }
    }
    .btn__text {
      color: white;
    }
  }
}

.social-icons {
  padding: 0;
  list-style: none;
  margin: 1em;

  li {
    display: inline-block;
    margin: 0.15em;
    position: relative;
    font-size: 1.2em;

  }

  svg {
    color: #fff;
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 30px;
    transition: all $timing ease-out;
    @media (max-width: $mob) {
      top: 12px;
      left: 12px;
    }
  }

  a {
    display: inline-block;

    &:before {
      @include transformScale();
      content: " ";
      width: 60px;
      height: 60px;
      border-radius: 100%;
      display: block;
      background: $lightBlue;
      transition: all $timing ease-out;
      @media (max-width: $mob) {
        width: 55px;
        height: 55px;
      }
    }

    &:hover:before {
      transform: scale(0);
      transition: all $timing ease-in;
    }

    &:hover svg {
      @include transformScale(2.2);
      color: white;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      transition: all $timing ease-in;
    }
  }

}
