@import "../style/variables.scss";

.pSkills {
  padding: 35px;
  background-color: $beige;
  padding-bottom: 60px;
  @media (max-width: $mob) {
    padding: 20px;
    padding-bottom: 50px;
  }
  &--title {
    position: relative;
    h2 {
      text-align: center;
      margin-top: 35px;
      margin-bottom: 170px;
      font-size: 75px;
      @media (max-width: 1180px) {
        font-size: 65px;
      }
      @media (max-width: $tab) {
        margin-bottom: 150px;
      }
      @media (max-width: $mob) {
        font-size: 45px;
        margin-bottom: 120px;
      }
    }
    > div {
      top: 160px;
      right: 30%;
      left: auto;
      @media (max-width: 1180px) {
        top: 170px;
      }
      @media (max-width: $tab) {
        top: 140px;
        left: 60%;
      }
      @media (max-width: $mob) {
        top: 110px;
        left: 150px;
      }
      img {
        transform: rotate(120deg);
        width: 100px;
        height: 100px;
        @media (max-width: 1180px) {
          width: 80px;
          height: 80px;
        }
        @media (max-width: $mob) {
          width: 60px;
          height: 60px;
        }
      }
      span {
        bottom: 100px;
        right: -15px;
        font-size: 30px;
        width: 170px;
        left: auto;
        top: -60px;
        transform: rotate(5deg);
        @media (max-width: 1180px) {
          bottom: 150px;
          font-size: 25px;
          top: -50PX;
        }
        @media (max-width: $mob) {
          font-size: 20px;
          left: 0;
          top: -20px;
          width: 180px;
        }
      }
    }
  }
  &--container {
    display: flex;
    @media (max-width: $tab) {
      flex-direction: column;
    }
    &--tech {
      width: 70%;
      margin-right: 5%;
      @media (max-width: $tab) {
        width: 100%;
        margin-right: 0;
      }
      .seen {
        .pSkills--container--tech--chart--skill > div{
          background-position: bottom left;
        }
      }
      &--chart {
        display: flex;
        height: 300px;
        justify-content: space-between;
        &--skill {
          background-color: #FFEFF4;
          display: flex;
          align-items: flex-end;
          position: relative;
          width: 10%;
          font-size: 13px;
          @media (max-width: 1500px) {
            font-size: 10px;
          }
          @media (max-width: 1180px) {
            font-size: 8px;
          }
          .pourcentage {
            position: absolute;
            top: 5px;
            left: 35%;
            color: #FDD0DE;
          }
          > div {
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            background: linear-gradient(to top, #D37C9D 50%, #FFEFF4 50%);
            background-size: 100% 200%;
            background-position: top left;
            transition: all .8s ease-out;
            > span {
              text-align: center;
              justify-content: center;
              margin-bottom: 10px;
              width: 80%;
              color: white;
              @media (max-width: $mob) {
                transform: rotate(270deg);
                white-space: nowrap;
                margin-bottom: 40px;
              }
            }
          }
        }
      }
      h3 {
        text-align: center;
        margin-top: 50px;
        font-size: 40px;
        @media (max-width: $mob) {
          font-size: 30px;
        }
      }
      &--others {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        @media (max-width: $mob) {
          margin-top: 30px;
        }
        div {
          background-color: #FFEFF4;
          color: $pink;
          border-radius: 70px;
          width: 120px;
          height: 120px;
          justify-content: center;
          display: flex;
          align-items: center;
          box-shadow: 0 19px 19px 0 rgba(0, 0, 0, 0.3), 0 15px 6px 0 rgba(0, 0, 0, 0.22);
          font-size: 25px;
          font-weight: 900;
          @media (max-width: 1180px) {
            width: 100px;
            height: 100px;
          }
          @media (max-width: $tab) {
            font-size: 20px;
          }
          @media (max-width: $mob) {
            width: 60px;
            height: 60px;
            font-size: 12px;
          }
        }
      }
    }
    &--soft {
      width: 30%;
      @media (max-width: $tab) {
        width: 100%;
      }
      h3 {
        text-align: center;
        margin-top: -120px;
        font-size: 40px;
        @media (max-width: $tab) {
          margin-bottom: 20PX;
          margin-top: 60px;
        }
        @media (max-width: $mob) {
          font-size: 30px;
        }
      }
      &--clouds {
        display: flex;
        justify-content: center;
        margin-bottom: 60px;
        @media (max-width: $tab) {
          justify-content: space-around;
        }
        @media (max-width: $mob) {
          display: block;
        }
        > div {
          @media (max-width: $tab) {
            display: flex;
            width: 50%;
            justify-content: space-around;
          }
          @media (max-width: $mob) {
            width: 100%;
          }
        }
        > div:first-child {
          > div:nth-child(even) {
            @media (max-width: $tab) {
              margin-top: 40px;
            }
          }
        }
        > div:last-child {
          margin-top: 50px;
          margin-left: 10%;
          @media (max-width: $tab) {
            margin-top: 0;
            margin-left: 0;
          }
          @media (max-width: $mob) {
            margin-top: 30px;
          }
          > div:nth-child(odd) {
            @media (max-width: $tab) {
              margin-top: 40px;
            }
          }
          > div:nth-child(even) {
            @media (max-width: $mob) {
              margin-top: 0px;
            }
          }
        }
      }
      > div:last-child {
        > span {
          margin-bottom: 20px;;
        }
      }
      button {
        width: 70px;
        border: 1px solid $pink;
        color: black;
        &::before {
          box-shadow: inset 0 0 0 1px $pink;
        }
        &:hover::before {
          box-shadow: inset 0 0 0 60px $pink;
        }
      }
    }
  }
}

.cloud {
  margin-top: 80px;
  width: 150px;
  height: 60px;
  background: $blue;
  color: white;
  box-shadow: 5px 7px rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 900;
  @media (max-width: 1180px) {
    width: 100px;
    height: 45px;
    font-size: 12px;
  }
  &::after {
    content: "";
    position: absolute;
    display: inline-block;
    background: inherit;
    border-radius: inherit;
    width: 60px;
    height: 60px;
    top: -20px;
    left: 20px;
    z-index: -1;
    @media (max-width: 1180px) {
      width: 40px;
      height: 40px;
      top: -10px;
      left: 10px;
    }
  }
  &:before {
    content: "";
    position: absolute;
    display: inline-block;
    background: inherit;
    border-radius: inherit;
    width: 80px;
    height: 80px;
    top: -30px;
    left: 60px;
    z-index: -1;
    @media (max-width: 1180px) {
      width: 55px;
      height: 55px;
      top: -18px;
      left: 35px;
    }
  }
}
