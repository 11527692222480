@import '@splidejs/react-splide/css';
@import "../style/variables.scss";

.splide__slide {
  display: flex;
  align-items: center;
}
.splide__arrow--prev {
  @media (max-width: $tab) {
    top: 150px !important;
  }
}
.splide__arrow--next {
  @media (max-width: $tab) {
    top: 150px !important;
  }
}
.splide__arrow {
  background-color: $pink;
  svg {
    fill: $lightPink;
  }
}

.splide {
  @media (max-width: $tab) {
    width: 100%;
  }
  &__slide {
    @media (max-width: $tab) {
      flex-direction: column;
    }
  }
}

.pProjects {
  background-color: $beige;
  @media (max-width: $mob) {
    padding-bottom: 60px;
  }
  h2 {
    text-align: center;
    padding-top: 35px;
    font-size: 65px;
    margin-bottom: 25px;
    @media (max-width: $mob) {
      margin-bottom: 60px;
    }
  }
  &--container {
    padding: 5% 1% 5% 5%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: $tab) {
      padding-bottom: 80px;
    }
    @media (max-width: $mob) {
      padding: 10px;
    }
    img {
      width: 500px;
      height: 500px;
      transition: opacity 0.3s ease;
      @media (max-width: $mob) {
        width: 250px;
        height: 250px;
      }
      &:hover {
        opacity: 50%;
      }
    }
    &--part {
      padding: 20px;
      h3 {
        text-align: center;
        font-size: 30px;
        margin-bottom: 30px;
        color: $blue;
        transition: color 0.3s ease;
        &:hover {
          color: $lightBlue;
        }
      }
      &--text {
        margin-bottom: 20px;
        font-size: 17px;
        line-height: 19px;
      }
      &--steps {
        > div {
          font-size: 17px;
          margin-bottom: 15px;
          line-height: 19px;
        }
      }
      &--techno {
        margin-top: 30px;
        display: flex;
        justify-content: space-evenly;
        p {
          font-size: 18px;
          margin: 10px;
          padding: 8px;
          background-color: #D37C9D;
          border-radius: 5px;
          color: white !important;
        }
      }
    }
  }
}
