@import "../style/variables.scss";

header {
  background-color: $blue;
  padding-top: 15px;
  padding-bottom: 15px;
  .pMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &--logo {
      height: 90px;
      width: 20%;
      padding-left: 5%;
      @media (max-width: $tab) {
        height: 70px;
        width: 15%;
        padding-left: 2%;
      }
      @media (max-width: $mob) {
        width: 30%;
      }
      img {
        height: 100%;
        @media (max-width: $tab) {
          width: 90px;
        }
      }
    }
    &--mobile {
      display: none;
      width: 30%;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      cursor: pointer;
      @media (max-width: $mob) {
        display: flex;
      }
      &--bar {
        height: 5px;
        margin-top: 4px;
        width: 40px;
        background-color: white;
        display: block;
        border-radius: 5px;
        transition: 0.3s ease;
      }
      &--bg {
        position: absolute;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 180px;
        border-top-left-radius: 50%;
        z-index: 1;
        margin: 30px 0 20px 20px;
        background-color: $blue;
        transition: 0.3s ease;
        width: 0px;
        height: 0px;
        right: 0;
      }
    }
    &--nav {
      width: 70%;
      @media (max-width: $tab) {
        width: 75%;
      }
      @media (max-width: $mob) {
        display: none;
        transition: 0.3s ease;
        z-index: 1;
        position: absolute;
        right: 10px;
        top: 90px;
        width: auto;
      }
      ul {
        display: flex;
        justify-content: space-between;
        font-size: 17px;
        font-weight: 500;
        text-transform: uppercase;
        @media (max-width: $tab) {
          font-size: 13px;
        }
        @media (max-width: $mob) {
          font-size: 17px;
          flex-direction: column;
          align-items: center;
        }
        li {
          display: flex;
          align-items: center;
          @media (max-width: $mob) {
            margin-bottom: 7px;
          }
        }
        a {
          color: white;
          cursor: pointer;
          white-space: nowrap;
          transition: filter 0.3s ease;
        }
        a::before,
        a::after {
          display: inline-block;
          opacity: 0;
          -webkit-transition: -webkit-transform 0.3s, opacity 0.2s;
          -moz-transition: -moz-transform 0.3s, opacity 0.2s;
          transition: transform 0.3s, opacity 0.2s;
        }
        a::before {
          margin-right: 10px;
          content: '<';
          -webkit-transform: translateX(20px);
          -moz-transform: translateX(20px);
          transform: translateX(20px);
        }
        a::after {
          margin-left: 10px;
          content: '>';
          -webkit-transform: translateX(-20px);
          -moz-transform: translateX(-20px);
          transform: translateX(-20px);
        }
        a:hover::before,
        a:hover::after,
        a:focus::before,
        a:focus::after {
          opacity: 1;
          -webkit-transform: translateX(0px);
          -moz-transform: translateX(0px);
          transform: translateX(0px);
          filter: brightness(85%);
        }
        a:hover {
          filter: brightness(85%);
        }
      }
    }
    &--lang {
      width: 20%;
      padding-right: 5%;
      display: flex;
      justify-content: flex-end;
      color: white;
      @media (max-width: $tab) {
        font-size: 14px;
      }
      @media (max-width: $mob) {
        display: none;
        position: absolute;
        right: 0;
        z-index: 2;
        margin-top: 360px;
        width: auto;
        margin-top: 420px;
      }
      span {
        filter: brightness(60%);
        color: white;
      }
      .active {
        filter: brightness(100%);
        font-weight: 800;
      }
      span:first-child {
        margin-right: 5px;
        cursor: pointer;
      }
      span:last-child {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

}

#bar1 {
  transform: translateY(-4px);
}

.show #bar1 {
  transform: translateY(4px) rotateZ(-45deg);
}

.show #bar2 {
  opacity: 0;
}

#bar3 {
  transform: translateY(4px);
}

.show #bar3 {
  transform: translate(-1px, -14px) rotateZ(45deg);
}

.show .pMenu--nav, .show .pMenu--lang {
  display: block;
}

.show .pMenu--mobile--bg {
  width: 200px;
  height: 475px;
}
